import 'normalize.css'
import * as THREE from 'three'
import FBXLoader from './FBXLoader'

document.addEventListener('DOMContentLoaded', () => {
    renderCroissant()
    lifeAdvice()
    document.querySelector('.phrase')!.addEventListener('click', lifeAdvice)
})

const quotes = [
    ['u smart. u loyal. i appreciate u.', 'DJ Khaled'],
    ['❤️ always be refactoring ❤️', 'unknown'],
    ['❤️ always be rebasing ❤️', 'unknown'],
    ['Never attribute to malice that which is adequately explained by stupidity.', "Robert J. Hanlon (Hanlon's Razor)"],
    ['we should call python programmers snake charmers or the other way around', '@red_shifts'],
    ['numpy is a great cat name', '@red_shifts']
]

let lastIdx: number | null = null

function lifeAdvice() {
    // Make sure we always get a new random index than the last
    let idx = null
    do {
        idx = Math.floor(Math.random() * quotes.length)
    } while (idx === lastIdx)
    lastIdx = idx

    const quote = quotes[idx]
    const [phrase, author] = quote

    const hotTakePhrase = document.querySelector('#hot-take .phrase')!
    hotTakePhrase.textContent = phrase

    const isTwitter = author.charAt(0) === '@'
    const newAuthor = isTwitter ? document.createElement('a') : document.createElement('div')
    newAuthor.id = 'hot-take'
    newAuthor.classList.add('author')
    newAuthor.textContent = author
    if (isTwitter) {
        ;(newAuthor as HTMLAnchorElement).href = `https://twitter.com/${author.substring(1)}`
    }
    const hotTakeAuthor = document.querySelector('#hot-take .author')!
    hotTakeAuthor.parentNode!.replaceChild(newAuthor, hotTakeAuthor)
}

async function renderCroissant() {
    // Setup renderer
    const canvas = document.querySelector<HTMLCanvasElement>('#renderer')!
    canvas.addEventListener('mousedown', _event => {
        const c = canvas as any
        if (!c.pointerLockElement) c.requestPointerLock && c.requestPointerLock()
    })
    document.addEventListener('mouseup', _event => {
        const d = document as any
        if (!!d.pointerLockElement) d.exitPointerLock && d.exitPointerLock()
    })
    const renderer = new THREE.WebGLRenderer({ alpha: true, canvas })

    // Camera
    const camera = new THREE.PerspectiveCamera(25, window.innerWidth / window.innerHeight, 0.1, 1000)
    camera.position.set(0, 0, 0)

    // Setup scene
    const scene = new THREE.Scene()
    scene.add(camera)

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 1.0)
    scene.add(ambientLight)

    // Load croissants, wonderful croissants
    const croissant = await loadFbx('croissant', '/static/croissant.fbx')
    croissant.scale.setScalar(0.1)
    croissant.position.set(0, 10, 0)
    croissant.rotation.x = Math.PI / 12
    croissant.rotation.z = Math.PI / 8
    scene.add(croissant)

    camera.position.set(0, 0, 50)
    camera.lookAt(croissant.position)

    // Croissant physics
    const MIN_VELOCITY = Math.PI / 12
    let v = Math.PI / 12
    const a = Math.PI / 400
    window.addEventListener('pointermove', event => {
        const { movementX, buttons } = event
        if (buttons === 1) {
            v = movementX / 10
        }
    })

    const clock = new THREE.Clock()
    const render = () => {
        const t = clock.getDelta()

        const absV = Math.abs(v)
        if (absV > MIN_VELOCITY) {
            const dA = (absV - MIN_VELOCITY) * a
            if (v > 0) {
                v -= dA
            } else {
                v += dA
            }
        }
        croissant.rotation.y += v * t

        renderer.render(scene, camera)
        requestAnimationFrame(render)
    }
    render()

    window.addEventListener('resize', () => {
        renderer.setSize(window.innerWidth, window.innerHeight)
        camera.aspect = window.innerWidth / window.innerHeight
        camera.updateProjectionMatrix()
    })
    window.dispatchEvent(new Event('resize'))
}

const loadedFbx: { [name: string]: THREE.IFbxSceneGraph } = {}

function loadFbx(name: string, filename: string) {
    return new Promise<THREE.IFbxSceneGraph>((resolve, reject) => {
        const fbxLoader = new FBXLoader()
        fbxLoader.load(
            filename,
            object => {
                ;(object as any).name = name
                if (loadedFbx[name]) {
                    console.log(`Warning: overwriting existing FBX '${name}'!`)
                }
                loadedFbx[name] = object
                resolve(object)
            },
            xhr => {
                // console.log(xhr.loaded/xhr.total*100 + '% loaded')
            },
            xhr => {
                const errMsg = `Error loading FBX '${name}': ${JSON.stringify(xhr)}!`
                console.error(errMsg)
                reject(errMsg)
            }
        )
    })
}
